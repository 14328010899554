import { Typography, Button, withStyles } from '@material-ui/core'
import React from 'react'

const StyledButton = withStyles({
    root: {
        background: '#33996f',
        borderRadius: '25px',
        border: 0,
        color: 'white',
        height: 48,
        padding: '15px 30px'
    },
    label: {
        textTransform: 'capitalize'
    }
})(Button)

const CallToAction = () => {
    return (
        <div className="cta">
            <div className="contentContainer cta__container">
                <h3>
                    &#8595; Is your website as slow as Los Angeles traffic?
                    &#8595;
                </h3>
                <p>
                    Roughly 200,000 business closed in 2020, 36% of small
                    business didn't have a website prior to 2020. One third of
                    America's business weren't prepared for 2020. Is your
                    website "Google Ready"?
                </p>
                <StyledButton variant="contained" href="/contact">
                    Schedule A Consultation
                </StyledButton>
            </div>
        </div>
    )
}

export default CallToAction
