import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import { CheckCircleTwoTone } from '@ant-design/icons'

const CardBodyItem = ({ text }) => (
    <div className="services__cardBody__item">
        <CheckCircleTwoTone className="icon" />
        <p>{text}</p>
    </div>
)

const Services = () => {
    return (
        <section className="services">
            <div className="contentContainer services__container">
                <h2>Services</h2>
                <div className="services__boxContainer">
                    <Paper
                        elevation={3}
                        className="services__sideBox services__card"
                    >
                        <div className="services__cardHeader">
                            <h3>Design</h3>
                        </div>
                        <div className="services__cardBody">
                            <CardBodyItem text="Design with Sales in Mind" />
                            <CardBodyItem text="Wireframes - Blueprints -Prototypes" />
                            <CardBodyItem text="Figma and Adobe XD" />
                            <CardBodyItem text="Mobile and Desktop Friendly" />
                            <CardBodyItem text="High End User Experience" />
                            <CardBodyItem text="Proper Web Accessibility" />
                        </div>
                    </Paper>
                    <Paper
                        elevation={5}
                        className="services__middleBox services__card"
                    >
                        <div className="services__cardHeader">
                            <h3>Full Stack Development</h3>
                        </div>
                        <div className="services__cardBody">
                            <CardBodyItem text="Landing Page Design" />
                            <CardBodyItem text="Cutting Edge Technologies" />
                            <CardBodyItem text="Innovative Solutions" />
                            <CardBodyItem text="Static & Dynamic Designs" />
                            <CardBodyItem text="Content Management (CMS)" />
                            <CardBodyItem text="Custom Administrator Dashboards" />
                            <CardBodyItem text="Custom Server" />
                        </div>
                    </Paper>
                    <Paper
                        elevation={3}
                        className="services__sideBox services__card"
                    >
                        <div className="services__cardHeader">
                            <h3>Consulting</h3>
                        </div>

                        <div className="services__cardBody">
                            <CardBodyItem text="SEO Auditing" />
                            <CardBodyItem text="Performance Auditing" />
                            <CardBodyItem text="Security Auditing" />
                            <CardBodyItem text="Keep Existing Hosts" />
                            <CardBodyItem text="Sales Audit" />
                            <CardBodyItem text="Google Analytics" />
                            <CardBodyItem text="Testing" />
                        </div>
                    </Paper>
                </div>
            </div>
        </section>
    )
}

export default Services
