import React from 'react'
import { motion } from 'framer-motion'
import BottomRightHex from './BottomRightHex'
import BottomLeftHex from './BottomLeftHex'
import MiddleHex from './MiddleHex'
import TopRightHex from './TopRightHex'
import TopHex from './TopHex'
import { duration } from '@material-ui/core'

const svgVariants = {
    transition: {
        staggerChildren: 0.3
    }
}

const hexVars = {
    init: {
        x: 0,
        y: 0
    },
    motion: {
        x: [2, -2, 2, -2, 2],
        y: [-2, 2, 2, -2, -2],
        transition: {
            type: 'spring',
            ease: 'linear',
            repeat: Infinity,
            duration: 3
        }
    }
}

const HexValues = () => {
    return (
        <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 933.26 807.02"
            variants={svgVariants}
            initial="init"
            animate="motion"
        >
            <TopHex
                variants={hexVars}
                key="1"
                initial="init"
                animate="motion"
            />
            <TopRightHex />
            <MiddleHex />
            <BottomLeftHex
                variants={{
                    ...hexVars,
                    motion: { ...hexVars.motion, x: [-2, 2, 2, -2] }
                }}
                initial="init"
                animate="motion"
                key="2"
            />
            <BottomRightHex
                variants={{
                    ...hexVars,
                    motion: {
                        ...hexVars.motion,
                        x: [-2, 2, 2, -2],
                        y: [2, -2, 2, -2, 2]
                    }
                }}
                initial="init"
                animate="motion"
                key="3"
            />
        </motion.svg>
    )
}

export default HexValues
