import React from 'react'

const MiddleHex = () => {
    return (
        <g id="Middle_Hex" data-name="Middle Hex">
            <path
                id="Purple_Hex"
                data-name="Purple Hex"
                d="M467.52,646.28c-27,0-54.06.51-81-.41-5.47-.19-13-4.38-15.79-9q-41.37-70.19-80.95-141.42c-2.83-5.11-2.57-14.66.32-19.86q39.15-70.55,80.22-140c3-5,11.12-9.68,16.94-9.76q80.25-1.12,160.52,0c5.82.08,14,4.71,17,9.71q41.07,69.42,80.23,140c2.89,5.21,3.19,14.77.36,19.88q-39.49,71.26-81,141.42c-2.75,4.66-10.29,8.89-15.75,9.07C521.58,646.79,494.54,646.28,467.52,646.28Z"
                style={{ fill: '#339', isolation: 'isolate' }}
            />
            <g
                id="Full_Stack_Web_Solutions"
                data-name="Full Stack Web Solutions"
            >
                <path
                    d="M347.67,440q.51-5,.71-8,.3-4.49.57-12.32t.27-10.56v-2.71q7.41.06,13.79.05t12.37-.05q-.4,5.67-.4,7.42l-2.14-.08-1.72,0-1.73,0h-4.85l-4.9.05-.22,5.74,3.57.08h4c1.15,0,2.9,0,5.27-.08-.18,3.1-.27,5.54-.27,7.29q-4-.12-5.88-.12-3.24,0-7.08.12l-.26,8.87,0,3.3V440l-5.3-.05Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M405.8,440c-1.88,0-3.44-.05-4.68-.05-1.55,0-3.1,0-4.66.05l0-3.37a14.34,14.34,0,0,1-1.5,1.66,8,8,0,0,1-1.34,1A9.78,9.78,0,0,1,392,440a12.67,12.67,0,0,1-2.07.48,15.56,15.56,0,0,1-2.37.17,14.69,14.69,0,0,1-3.69-.45,8.07,8.07,0,0,1-3.05-1.54,5.56,5.56,0,0,1-1.8-2.47,9.66,9.66,0,0,1-.48-3.2c0-.29,0-.75.08-1.38L379,426c.12-2,.22-4.32.32-6.82.07-1.53.1-3,.1-4.33,2,0,3.77.05,5.2.05s3,0,4.78-.05l-.77,9.38c-.11,1.43-.18,2.4-.18,2.92s0,.86,0,1.05c0,1.72.35,2.89,1.05,3.49a3.69,3.69,0,0,0,2.51.91,4,4,0,0,0,1.18-.17,4.79,4.79,0,0,0,1.14-.49,4,4,0,0,0,.86-.74,5,5,0,0,0,.75-1.13,7.67,7.67,0,0,0,.59-1.75,26.59,26.59,0,0,0,.42-3.3c.06-1,.15-2.65.26-5l.19-5.14c2.12,0,3.86.05,5.22.05,1.53,0,3.06,0,4.6-.05q-.57,6.25-.88,11.89T405.8,440Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M411.31,440l.44-5.3.95-18.41.27-9.91c2.29,0,4,.05,5.15.05,1.5,0,3.15,0,5-.05l-.56,7.56-.9,18.62-.2,7.44-4.76,0Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M426.92,440l.44-5.3,1-18.41.27-9.91c2.28,0,4,.05,5.15.05,1.5,0,3.15,0,5-.05l-.57,7.56-.9,18.62L437,440l-4.75,0Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M455.2,439.49c-.22-3.49-.46-6.29-.74-8.39a26.43,26.43,0,0,0,4.92,1.71,17.74,17.74,0,0,0,3.79.48,7.79,7.79,0,0,0,3.42-.63,2,2,0,0,0,1.27-1.86,2.4,2.4,0,0,0-.13-.8,3,3,0,0,0-.47-.86,6.49,6.49,0,0,0-1-1.05l-4.64-4c-1.09-.92-1.85-1.58-2.27-2a15.41,15.41,0,0,1-2-2.34,7.85,7.85,0,0,1-1.06-2.21,8.56,8.56,0,0,1,.2-5.32,8.89,8.89,0,0,1,1.58-2.69,9.26,9.26,0,0,1,2.81-2.17,12.44,12.44,0,0,1,3.75-1.24,24.08,24.08,0,0,1,4.11-.34,47.41,47.41,0,0,1,9.06,1q.08,1.71.18,3.12c.06.94.22,2.6.46,5a23.46,23.46,0,0,0-4.13-1.27,18.33,18.33,0,0,0-3.23-.35,6.38,6.38,0,0,0-3.19.71,2.07,2.07,0,0,0-1.27,1.77,2.46,2.46,0,0,0,.2,1,4.3,4.3,0,0,0,.74,1.1,12.63,12.63,0,0,0,1.42,1.38c.6.5,1.52,1.24,2.79,2.23l1.77,1.4c.53.43,1.09.95,1.7,1.55a17.86,17.86,0,0,1,1.58,1.8,10.54,10.54,0,0,1,1,1.63,7.18,7.18,0,0,1,.53,1.49,7.5,7.5,0,0,1,.17,1.61,9,9,0,0,1-4.06,7.52,12.53,12.53,0,0,1-4.28,1.82,22.82,22.82,0,0,1-4.91.51A55.08,55.08,0,0,1,455.2,439.49Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M501.4,432.77c-.29,1.38-.55,2.73-.76,4l-.54,3a35.1,35.1,0,0,1-3.81.68,24.22,24.22,0,0,1-2.65.15,18.09,18.09,0,0,1-3.72-.35,6.09,6.09,0,0,1-2.71-1.31,4.81,4.81,0,0,1-1.47-2.18,8.29,8.29,0,0,1-.4-2.61c0-.16,0-.46,0-.87s.07-1.35.17-2.8l.54-8.52h-3.84c.08-1.13.16-2.63.22-4.48l.08-2.29h4.08c.09-.76.22-2.41.36-4.95l4.22-.87c.77-.14,2.42-.52,4.93-1.13-.11,2.15-.2,4.47-.25,6.95h5.62q-.19,5.11-.2,6.77h-5.66l-.3,6.16c0,.92,0,1.51,0,1.77a6.48,6.48,0,0,0,.26,2,1.87,1.87,0,0,0,.91,1.13,3.07,3.07,0,0,0,1.44.35,8.69,8.69,0,0,0,1.23-.09A20.07,20.07,0,0,0,501.4,432.77Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M506.87,422c.31-1.62.51-2.74.59-3.36s.21-1.58.39-2.89a58.05,58.05,0,0,1,12.2-1.55,20.2,20.2,0,0,1,5.32.65,7.85,7.85,0,0,1,3.77,2.1,5.91,5.91,0,0,1,1.35,4.17q0,1.38-.37,6.2T529.56,440c-1.61,0-3.21-.05-4.81-.05s-3.18,0-4.93.05q.34-2.7.75-11.18c-.62.3-1.11.51-1.46.64s-1,.33-1.86.59-1.58.49-2.07.67a4.92,4.92,0,0,0-1.31.69,2.56,2.56,0,0,0-.78.86,2,2,0,0,0-.24,1,1.85,1.85,0,0,0,.67,1.43,3.11,3.11,0,0,0,2.12.59,7.43,7.43,0,0,0,1.11-.08,10.5,10.5,0,0,0,1.62-.43c-.48,1.26-1,3.11-1.7,5.54a25,25,0,0,1-4,.34,13.2,13.2,0,0,1-5.09-.85,6.34,6.34,0,0,1-3.13-2.74,7.55,7.55,0,0,1-1.09-3.8,6.53,6.53,0,0,1,.4-2.26,7.73,7.73,0,0,1,1.27-2.17,8.71,8.71,0,0,1,2-1.81,14.43,14.43,0,0,1,3.16-1.42,41.67,41.67,0,0,1,5.09-1.25c.85-.17,1.63-.34,2.35-.53a11.19,11.19,0,0,0,1.53-.49,2.54,2.54,0,0,0,.73-.5,1.62,1.62,0,0,0,.41-.59,1.75,1.75,0,0,0,.12-.65,1.62,1.62,0,0,0-.78-1.44,4.32,4.32,0,0,0-2.32-.51A30.44,30.44,0,0,0,506.87,422Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M556.08,432.16c-.08.65-.28,1.86-.59,3.62l-.64,3.94a32.3,32.3,0,0,1-7.56.93,18.38,18.38,0,0,1-4.71-.54,10.85,10.85,0,0,1-4.28-2.26,9.88,9.88,0,0,1-2.84-4,16.37,16.37,0,0,1-1.06-6,17,17,0,0,1,1-5.89,11.58,11.58,0,0,1,3-4.66,10.4,10.4,0,0,1,4.49-2.47,22.4,22.4,0,0,1,5.44-.62,34.87,34.87,0,0,1,3.52.18,35.45,35.45,0,0,1,4.38.8l0,.57c0,.59.09,1.19.11,1.81s.08,1.59.16,2.92l.15,2.19a19.21,19.21,0,0,0-6.23-1.23,7.39,7.39,0,0,0-3.41.75,4.75,4.75,0,0,0-2.17,2.17,7.26,7.26,0,0,0-.7,3.28,7.15,7.15,0,0,0,.63,3.18,4.09,4.09,0,0,0,2,1.92,7,7,0,0,0,3,.64,14.41,14.41,0,0,0,2-.17c.76-.12,1.43-.24,2-.38A21.56,21.56,0,0,0,556.08,432.16Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M560.49,440c.22-2.1.39-3.81.49-5.12q.19-2.68.39-6.72l.27-5.84q.17-4,.24-7.59.11-4.33.1-8.35c2.3,0,4,.05,5.18.05,1.33,0,2.91,0,4.73-.05q-.57,6.47-1,16T570.38,440c-1.91,0-3.51-.05-4.83-.05C564.05,440,562.36,440,560.49,440Zm10.86-14.15,4.23-6.1,3.18-4.89c2.27,0,4,.05,5.15.05,1.61,0,3.51,0,5.71-.05l-4.9,6.37Q583,423.43,581.2,426q1.72,3.18,8.3,14.05c-1.92,0-3.65-.05-5.2-.05s-3.38,0-5.42.05l-1.52-3.08c-.54-1.07-1-2-1.41-2.69-.14-.29-1.27-2.3-3.37-6Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M417.77,500.54l-.84-4.53c-.2-1.07-.5-2.56-.91-4.48l-4-18.4-1.46-6.21c2.08,0,3.75.05,5,.05,1.45,0,3.13,0,5-.05q.67,4,1.06,5.89l2.07,9.92q.64,3.21,1.43,7.81l.81-3.45,2.93-11.38,2-8.79c2.25,0,4.16.05,5.72.05s3.58,0,5.69-.05l1.55,10.37,2.15,13.2,1-3.62q.27-.93.57-1.89c.2-.65.61-1.92,1.23-3.82l3-9.46,1.41-4.78,4.92,0,4.89,0-9.54,28.62-1.53,5q-3.69,0-6.45-.05t-6.43.05l-.57-3.37-2.16-11.75c-.12-.71-.22-1.43-.31-2.18s-.22-1.91-.38-3.49l-.86,3.82c-.22.86-.44,1.72-.67,2.59l-4,14.38q-3.72,0-6.18-.05Q421.34,500.49,417.77,500.54Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M490,493.1q-.31,1.48-1.23,7.14c-1.9.38-3.5.63-4.8.76s-2.61.18-3.94.18a33.45,33.45,0,0,1-5.4-.4,12.61,12.61,0,0,1-4.24-1.44,10.29,10.29,0,0,1-3-2.58,10.89,10.89,0,0,1-1.83-3.65,15.17,15.17,0,0,1-.63-4.36,15.83,15.83,0,0,1,1.26-6.16,12.09,12.09,0,0,1,3.24-4.71,11.65,11.65,0,0,1,4.56-2.41,19.7,19.7,0,0,1,5.3-.72,21.33,21.33,0,0,1,5.36.66,9.77,9.77,0,0,1,4.32,2.28,9.25,9.25,0,0,1,2.52,3.67,12.67,12.67,0,0,1,.75,4.4,16.69,16.69,0,0,1-.32,3.13l-17.26,0c0,.26,0,.49,0,.71a4.37,4.37,0,0,0,1.9,3.9,8.25,8.25,0,0,0,4.63,1.25,21.3,21.3,0,0,0,3.71-.38A51.48,51.48,0,0,0,490,493.1Zm-15.14-8H483q0-.42,0-.72a4.78,4.78,0,0,0-.46-2.19,2.84,2.84,0,0,0-1.3-1.33,4.28,4.28,0,0,0-1.91-.42,4.34,4.34,0,0,0-1.43.24,3.69,3.69,0,0,0-1.24.69,4.37,4.37,0,0,0-.93,1,5.14,5.14,0,0,0-.54,1.15A13,13,0,0,0,474.88,485.15Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M495.39,500.54q.69-7.76,1.21-18.08t.51-15.54c1.91,0,3.69.05,5.35.05s3.22,0,4.78-.05q-.54,3.84-.78,8.45c1.49-.25,2.64-.41,3.44-.49s1.6-.13,2.39-.13a15.9,15.9,0,0,1,6.49,1.2,9.58,9.58,0,0,1,4.38,4,13.22,13.22,0,0,1,1.69,6.92,17.7,17.7,0,0,1-1.6,7.52,10.9,10.9,0,0,1-10.54,6.76,11.63,11.63,0,0,1-2.54-.27,8.76,8.76,0,0,1-2.19-.8,8.46,8.46,0,0,1-1.65-1.12,17.53,17.53,0,0,1-1.53-1.6l-.09,3.15c-1.75,0-3.23-.05-4.41-.05Zm10.37-18.25c-.12,2.73-.17,4.39-.17,5A13.31,13.31,0,0,0,506,491a4,4,0,0,0,1.55,2.17,4.39,4.39,0,0,0,2.58.81,4.29,4.29,0,0,0,2.59-.81,5,5,0,0,0,1.68-2.38,9.3,9.3,0,0,0,.58-3.29,6,6,0,0,0-1.39-4.15,5.1,5.1,0,0,0-4-1.56A16.09,16.09,0,0,0,505.76,482.29Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M350.7,560.55c-.22-3.5-.46-6.3-.74-8.4a26.43,26.43,0,0,0,4.92,1.71,17.74,17.74,0,0,0,3.79.48,7.8,7.8,0,0,0,3.42-.62,2,2,0,0,0,1.27-1.86,2.36,2.36,0,0,0-.13-.8,3.14,3.14,0,0,0-.47-.87,6.48,6.48,0,0,0-1-1l-4.64-4c-1.09-.92-1.85-1.59-2.27-2a14,14,0,0,1-2-2.34,7.8,7.8,0,0,1-1.06-2.2,8.4,8.4,0,0,1-.33-2.38,8.3,8.3,0,0,1,.53-2.94,9.06,9.06,0,0,1,1.58-2.7,9.24,9.24,0,0,1,2.81-2.16,12.46,12.46,0,0,1,3.75-1.25,25.15,25.15,0,0,1,4.11-.33,47.41,47.41,0,0,1,9.06,1c.05,1.13.11,2.17.17,3.12s.23,2.59.47,5a22.65,22.65,0,0,0-4.13-1.27,17.53,17.53,0,0,0-3.23-.36,6.38,6.38,0,0,0-3.19.72,2,2,0,0,0-1.27,1.77,2.46,2.46,0,0,0,.2,1,4.21,4.21,0,0,0,.74,1.1,12.63,12.63,0,0,0,1.42,1.38c.6.5,1.52,1.24,2.79,2.22l1.77,1.41a22.24,22.24,0,0,1,1.7,1.55,17.86,17.86,0,0,1,1.58,1.8,10.5,10.5,0,0,1,1,1.62,7.39,7.39,0,0,1,.53,1.49,7.53,7.53,0,0,1,.17,1.62,9.05,9.05,0,0,1-4.06,7.52,12.51,12.51,0,0,1-4.28,1.81,22.26,22.26,0,0,1-4.91.52A56,56,0,0,1,350.7,560.55Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M393.23,535.28a18.38,18.38,0,0,1,5.88.94,13.17,13.17,0,0,1,4.76,2.69,10.91,10.91,0,0,1,2.87,4.12,13.62,13.62,0,0,1,.92,5.06,15.13,15.13,0,0,1-.82,5,12.32,12.32,0,0,1-2.8,4.46,12.07,12.07,0,0,1-5,3.11,19.85,19.85,0,0,1-6.44,1,20.07,20.07,0,0,1-6-.91,12.94,12.94,0,0,1-4.81-2.66,11.16,11.16,0,0,1-2.91-4.19,13.67,13.67,0,0,1-1-5.12,13.9,13.9,0,0,1,.95-5.13,12,12,0,0,1,3-4.38,13.87,13.87,0,0,1,5.09-3A19.1,19.1,0,0,1,393.23,535.28Zm-.17,7.24a4.86,4.86,0,0,0-4.67,3.19,9,9,0,0,0-.63,3.41,5.8,5.8,0,0,0,1.36,4.15,4.46,4.46,0,0,0,3.4,1.44,4.88,4.88,0,0,0,2.78-.82,5,5,0,0,0,1.86-2.44,9.41,9.41,0,0,0,.63-3.46,7.34,7.34,0,0,0-.5-2.76,4.08,4.08,0,0,0-1.61-2A4.68,4.68,0,0,0,393.06,542.52Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M411.26,561.07l.44-5.3.95-18.41.27-9.91c2.29,0,4,0,5.16,0,1.49,0,3.14,0,4.95,0l-.56,7.56-.9,18.62-.2,7.44-4.76,0Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M454.49,561.07c-1.87,0-3.43-.05-4.68-.05-1.55,0-3.1,0-4.66.05l0-3.38a14.58,14.58,0,0,1-1.5,1.67,8,8,0,0,1-1.34,1,9.78,9.78,0,0,1-1.63.73,12.67,12.67,0,0,1-2.07.48,17,17,0,0,1-2.37.17,14.11,14.11,0,0,1-3.68-.46,7.76,7.76,0,0,1-3.06-1.54,5.61,5.61,0,0,1-1.8-2.46,9.76,9.76,0,0,1-.47-3.2c0-.3,0-.76.07-1.38l.39-5.59c.12-2.05.22-4.33.32-6.82.07-1.53.1-3,.1-4.34,2,0,3.77.05,5.2.05s3,0,4.78-.05l-.77,9.39c-.11,1.42-.17,2.4-.18,2.91s0,.87,0,1c0,1.72.35,2.89,1.05,3.5a3.69,3.69,0,0,0,2.51.91,4.5,4.5,0,0,0,1.19-.17A4.93,4.93,0,0,0,443,553a4.22,4.22,0,0,0,.87-.73,5.1,5.1,0,0,0,.74-1.14,7.51,7.51,0,0,0,.59-1.75,26.59,26.59,0,0,0,.42-3.3c.06-1,.15-2.64.26-5l.2-5.15c2.11,0,3.85.05,5.21.05,1.53,0,3.06,0,4.6-.05q-.56,6.26-.88,11.9T454.49,561.07Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M478.37,553.83c-.29,1.38-.55,2.72-.76,4l-.54,3a38.37,38.37,0,0,1-3.81.69,24.18,24.18,0,0,1-2.64.15,17.25,17.25,0,0,1-3.72-.36,6,6,0,0,1-2.71-1.3,4.81,4.81,0,0,1-1.48-2.18,8.59,8.59,0,0,1-.39-2.61c0-.17,0-.46,0-.88s.07-1.35.17-2.79l.54-8.53h-3.84c.08-1.13.16-2.62.22-4.48l.08-2.29h4.08c.1-.75.22-2.4.37-4.95l4.21-.86c.77-.15,2.42-.53,4.93-1.13q-.17,3.23-.24,6.94h5.61c-.13,3.42-.19,5.67-.19,6.77h-5.67l-.3,6.16c0,.92-.05,1.51-.05,1.77a6.55,6.55,0,0,0,.26,2,1.9,1.9,0,0,0,.91,1.13,3.07,3.07,0,0,0,1.45.34,10.71,10.71,0,0,0,1.23-.08C476.53,554.3,477.29,554.12,478.37,553.83Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M481.38,561.07c.23-2,.4-3.66.51-5s.23-3,.33-5,.2-4.25.28-6.7.15-5.28.2-8.52c2.14,0,3.85.05,5.13.05s2.84,0,5-.05c-.29,3.11-.49,5.83-.61,8.18s-.21,4.83-.24,7.46q-.11,7-.1,9.51c-2,0-3.63-.05-5-.05S483.64,561,481.38,561.07Zm1.55-28.3c.1-1.95.15-3.5.15-4.63,0-.33,0-.77,0-1.33,2,0,3.66.05,5,.05,1.86,0,3.48,0,4.88-.05-.05.51-.08.93-.1,1.28l-.15,3c0,.46-.05,1-.05,1.63-1.62,0-3.18,0-4.68,0S484.74,532.74,482.93,532.77Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M511.92,535.28a18.36,18.36,0,0,1,5.87.94,13.21,13.21,0,0,1,4.77,2.69,11,11,0,0,1,2.87,4.12,13.83,13.83,0,0,1,.92,5.06,15.13,15.13,0,0,1-.82,5,12.45,12.45,0,0,1-2.8,4.46,12.07,12.07,0,0,1-5,3.11,20.38,20.38,0,0,1-12.45.13,13,13,0,0,1-4.82-2.66,11.13,11.13,0,0,1-2.9-4.19,13.68,13.68,0,0,1-1-5.12,14.11,14.11,0,0,1,.95-5.13,12,12,0,0,1,3-4.38,13.82,13.82,0,0,1,5.08-3A19.12,19.12,0,0,1,511.92,535.28Zm-.17,7.24a4.86,4.86,0,0,0-4.67,3.19,9,9,0,0,0-.63,3.41,5.8,5.8,0,0,0,1.36,4.15,4.45,4.45,0,0,0,3.39,1.44,4.89,4.89,0,0,0,2.79-.82,5,5,0,0,0,1.86-2.44,9.41,9.41,0,0,0,.62-3.46,7.34,7.34,0,0,0-.49-2.76,4.14,4.14,0,0,0-1.61-2A4.68,4.68,0,0,0,511.75,542.52Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M529.75,561.07q.71-5.6,1.09-12.35t.45-12.8c2,0,3.67.05,5.1.05,1.66,0,3.26,0,4.79-.05l-.25,3.35a18,18,0,0,1,1.4-1.44,9,9,0,0,1,1.47-1,9.75,9.75,0,0,1,1.86-.83,14.44,14.44,0,0,1,2.15-.5,15,15,0,0,1,2.46-.19,16,16,0,0,1,3.46.38,9.21,9.21,0,0,1,2.81,1.09,6.49,6.49,0,0,1,1.9,1.75,5.57,5.57,0,0,1,1,2.25,12,12,0,0,1,.25,2.49c0,.62-.07,2-.2,4l-.43,8.91c-.08,1.76-.12,3.38-.12,4.88-1.66,0-3.21-.05-4.65-.05-1.95,0-3.73,0-5.34.05q.22-2.25.47-6.66t.25-6.22c0-1.86-.36-3.15-1.07-3.88a3.64,3.64,0,0,0-2.7-1.1,4.41,4.41,0,0,0-2.7.94,5.87,5.87,0,0,0-2,2.72,22.11,22.11,0,0,0-1,5.87c-.19,2.73-.31,5.5-.38,8.33-1.64,0-3.24-.05-4.8-.05S531.67,561,529.75,561.07Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M564.08,560.6c-.06-.72-.13-1.48-.2-2.28s-.17-1.89-.32-3.29l-.19-1.77a38.33,38.33,0,0,0,4.1,1.39,12.19,12.19,0,0,0,3.17.46,7.38,7.38,0,0,0,2-.22,2.2,2.2,0,0,0,1.1-.64,1.35,1.35,0,0,0,.34-.87,1.3,1.3,0,0,0-.26-.77,5.06,5.06,0,0,0-1.16-1.05c-1.35-1-2.53-1.88-3.55-2.68a14.75,14.75,0,0,1-2.31-2.15,6.77,6.77,0,0,1-1.18-2,6.34,6.34,0,0,1-.4-2.24,6.77,6.77,0,0,1,1.16-3.81,6.63,6.63,0,0,1,3.45-2.58,17,17,0,0,1,5.56-.8,55.51,55.51,0,0,1,8.74.89l.39,6.89a15.66,15.66,0,0,0-5.73-1.18,7.37,7.37,0,0,0-2.82.4,1.3,1.3,0,0,0-.88,1.23,1.34,1.34,0,0,0,.1.52,1.85,1.85,0,0,0,.36.51,4.65,4.65,0,0,0,.64.56l1.86,1.31c1,.69,1.75,1.26,2.31,1.7a13,13,0,0,1,2.11,2,7.42,7.42,0,0,1,1.11,2,6.5,6.5,0,0,1,.38,2.21,6.37,6.37,0,0,1-.85,3.21A6.8,6.8,0,0,1,580.5,560a11.9,11.9,0,0,1-3.71,1.37,22.2,22.2,0,0,1-4.11.38,31.38,31.38,0,0,1-3.59-.25C567.66,561.3,566,561,564.08,560.6Z"
                    style={{ fill: '#fff' }}
                />
            </g>
        </g>
    )
}

export default MiddleHex
