import React from 'react'

const TopRightHex = () => {
    return (
        <g id="Top_Left_Hex" data-name="Top Left Hex">
            <path
                id="Light_Blue_Hex-2"
                data-name="Light Blue Hex"
                d="M753.52,482.69c-27,0-54.06.5-81-.42-5.47-.19-13-4.38-15.79-9q-41.37-70.19-80.95-141.43c-2.83-5.1-2.57-14.65.32-19.86q39.15-70.54,80.22-140c3-5,11.12-9.68,16.94-9.76q80.25-1.11,160.52,0c5.82.08,14,4.7,17,9.7q41.07,69.44,80.23,140c2.89,5.21,3.19,14.78.36,19.88q-39.49,71.28-81,141.42c-2.75,4.66-10.29,8.89-15.75,9.08C807.58,483.19,780.54,482.69,753.52,482.69Z"
                style={{ fill: '#1a4790', isolation: 'isolate' }}
            />
        </g>
    )
}

export default TopRightHex
