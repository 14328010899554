import React from 'react'
import { motion } from 'framer-motion'

const TopHex = ({ ...rest }) => {
    return (
        <g id="Top_Hex" data-name="Top Hex">
            <motion.path
                id="Dark_Blue_Hex-2"
                data-name="Dark Blue Hex"
                d="M467.52,321.06c-27,0-54.06.5-81-.42-5.47-.19-13-4.38-15.79-9q-41.37-70.19-80.95-141.42c-2.83-5.11-2.57-14.66.32-19.86q39.15-70.54,80.22-140c3-5,11.12-9.68,16.94-9.76q80.25-1.12,160.52,0c5.82.08,14,4.71,17,9.71q41.07,69.42,80.23,140c2.89,5.21,3.19,14.77.36,19.88q-39.49,71.27-81,141.42c-2.75,4.66-10.29,8.89-15.75,9.07C521.58,321.56,494.54,321.06,467.52,321.06Z"
                style={{ fill: '#002b70' }}
                {...rest}
            />
            <g id="Professional">
                <motion.path
                    d="M345,172.27l.39-5,.5-9.77.22-6.91c0-.75,0-1.49,0-2.23,0-.56,0-1.48,0-2.75,1.71,0,3.1,0,4.14,0s2.37,0,4.12-.08,3.22-.09,4.44-.09a16.58,16.58,0,0,1,3.87.33,7,7,0,0,1,2.28,1.05,6.51,6.51,0,0,1,1.68,1.67,7.2,7.2,0,0,1,1,2.17,9.57,9.57,0,0,1,.33,2.49,9.28,9.28,0,0,1-1.27,4.79,7.93,7.93,0,0,1-3.58,3.24,12,12,0,0,1-5.17,1.08,14.5,14.5,0,0,1-2.44-.24c-.11-.6-.3-1.49-.59-2.67s-.52-2.07-.7-2.64a8.73,8.73,0,0,0,1.8.17A4,4,0,0,0,359,156a3.31,3.31,0,0,0,.76-3.5,2,2,0,0,0-.69-.85,2.54,2.54,0,0,0-1-.43,8.25,8.25,0,0,0-1.29-.1c-.47,0-1,0-1.47,0l-1.64.12c-.16,2.51-.29,5.31-.38,8.38q-.25,8.4-.25,10.08v2.54l-3.89,0Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M370.21,172.27l.6-8.68.19-5c0-.89,0-1.81,0-2.75s0-2.13,0-3.54c1.57,0,2.82,0,3.75,0s2.32,0,3.73,0l-.08,2.21a12.94,12.94,0,0,1,1.25-1.28,6.18,6.18,0,0,1,1.11-.76,5.24,5.24,0,0,1,1.31-.51,6.09,6.09,0,0,1,1.43-.16,7.11,7.11,0,0,1,1.56.19c0,1.42,0,2.59.1,3.52l.13,3a7.11,7.11,0,0,0-2-.32,5.37,5.37,0,0,0-2,.35,4.2,4.2,0,0,0-1.5,1,5,5,0,0,0-1,1.73,12.31,12.31,0,0,0-.55,3.28c-.1,1.5-.16,3.21-.16,5.14,0,.67,0,1.51.08,2.52-1.26,0-2.53,0-3.81,0S371.65,172.24,370.21,172.27Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M399,151.82a14.53,14.53,0,0,1,4.66.74,10.42,10.42,0,0,1,3.78,2.14,8.62,8.62,0,0,1,2.28,3.26,11,11,0,0,1,.73,4,12,12,0,0,1-.66,4,9.79,9.79,0,0,1-2.21,3.53,9.56,9.56,0,0,1-4,2.47,16.22,16.22,0,0,1-9.87.1,10.09,10.09,0,0,1-3.82-2.11,8.75,8.75,0,0,1-2.3-3.32,10.74,10.74,0,0,1-.77-4.06,11.12,11.12,0,0,1,.75-4.06A9.46,9.46,0,0,1,390,155a11,11,0,0,1,4-2.37A15,15,0,0,1,399,151.82Zm-.13,5.74a3.8,3.8,0,0,0-3.7,2.53,7,7,0,0,0-.5,2.7,4.64,4.64,0,0,0,1.07,3.29,3.54,3.54,0,0,0,2.7,1.15,3.83,3.83,0,0,0,2.2-.66,4,4,0,0,0,1.48-1.93,7.53,7.53,0,0,0,.5-2.74,5.85,5.85,0,0,0-.39-2.19,3.31,3.31,0,0,0-1.28-1.56A3.65,3.65,0,0,0,398.9,157.56Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M414.23,172.27q.38-3.75.9-14.34h-2.39c0-.4.1-1.26.17-2.58s.11-2.26.13-2.85h2.77c0-.52,0-1,0-1.35a15.86,15.86,0,0,1,.19-2.81,4.33,4.33,0,0,1,.68-1.73,4.62,4.62,0,0,1,1.41-1.27,5.71,5.71,0,0,1,2.05-.75,14,14,0,0,1,2.37-.19,21.57,21.57,0,0,1,2.45.14,22.07,22.07,0,0,1,3.16.64c0,2.07.12,3.79.25,5.15a10.28,10.28,0,0,0-3.48-.7,2.52,2.52,0,0,0-1.65.49,2.23,2.23,0,0,0-.59,1.79l0,.59h4.49c-.11,1.5-.21,3.31-.31,5.43h-4.06l-.65,14.34c-1.55,0-2.88,0-4,0S415.71,172.24,414.23,172.27Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M448.54,166.37c-.16.78-.49,2.67-1,5.66q-2.26.45-3.81.6c-1,.1-2.07.14-3.13.14a25.67,25.67,0,0,1-4.27-.32,9.7,9.7,0,0,1-3.37-1.14,8.11,8.11,0,0,1-2.37-2,8.51,8.51,0,0,1-1.46-2.9,12.1,12.1,0,0,1-.5-3.45,12.55,12.55,0,0,1,1-4.89,9.62,9.62,0,0,1,2.57-3.73,9.23,9.23,0,0,1,3.62-1.92,16,16,0,0,1,4.2-.56,17.36,17.36,0,0,1,4.25.51,7.88,7.88,0,0,1,3.42,1.81,7.42,7.42,0,0,1,2,2.91,10.24,10.24,0,0,1,.59,3.5,13.6,13.6,0,0,1-.25,2.48l-13.69,0c0,.21,0,.39,0,.56a3.44,3.44,0,0,0,1.5,3.09,6.46,6.46,0,0,0,3.67,1,15.83,15.83,0,0,0,2.94-.31A39.38,39.38,0,0,0,448.54,166.37Zm-12-6.31H443c0-.22,0-.41,0-.57a3.92,3.92,0,0,0-.36-1.74,2.25,2.25,0,0,0-1-1.05,3.38,3.38,0,0,0-1.51-.33,3.56,3.56,0,0,0-1.13.18,3.19,3.19,0,0,0-1,.55,3.38,3.38,0,0,0-.73.83,3.72,3.72,0,0,0-.43.91A10,10,0,0,0,436.53,160.06Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M452.72,171.9c-.05-.58-.1-1.18-.15-1.81s-.14-1.5-.26-2.61l-.15-1.41a29.36,29.36,0,0,0,3.25,1.11,9.93,9.93,0,0,0,2.52.36,5.54,5.54,0,0,0,1.55-.18,1.67,1.67,0,0,0,.86-.5,1.11,1.11,0,0,0,.28-.69,1,1,0,0,0-.21-.61,4.22,4.22,0,0,0-.92-.83q-1.61-1.17-2.82-2.13a12.66,12.66,0,0,1-1.83-1.7,5.52,5.52,0,0,1-.93-1.6,5,5,0,0,1-.33-1.78,5.4,5.4,0,0,1,.93-3,5.3,5.3,0,0,1,2.74-2,13.43,13.43,0,0,1,4.4-.63,43.57,43.57,0,0,1,6.93.7l.32,5.47a12.27,12.27,0,0,0-4.55-.94,5.79,5.79,0,0,0-2.24.32,1,1,0,0,0-.69,1,1.14,1.14,0,0,0,.07.41,1.41,1.41,0,0,0,.29.41,3.37,3.37,0,0,0,.51.44l1.47,1.05c.78.54,1.39,1,1.84,1.34a11.34,11.34,0,0,1,1.67,1.59,5.65,5.65,0,0,1,.87,1.57,4.85,4.85,0,0,1,.31,1.75,5,5,0,0,1-.68,2.54,5.3,5.3,0,0,1-2,1.95,9.26,9.26,0,0,1-2.94,1.08,17.41,17.41,0,0,1-3.26.3,25.78,25.78,0,0,1-2.84-.19C455.56,172.45,454.23,172.22,452.72,171.9Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M472,171.9c-.05-.58-.1-1.18-.16-1.81s-.13-1.5-.25-2.61l-.16-1.41a29.44,29.44,0,0,0,3.26,1.11,9.92,9.92,0,0,0,2.51.36,5.44,5.44,0,0,0,1.55-.18,1.66,1.66,0,0,0,.87-.5,1,1,0,0,0,.07-1.3,4.34,4.34,0,0,0-.93-.83c-1.06-.78-2-1.49-2.81-2.13a12.66,12.66,0,0,1-1.83-1.7,5.8,5.8,0,0,1-.94-1.6,5.18,5.18,0,0,1-.32-1.78,5.4,5.4,0,0,1,.93-3,5.31,5.31,0,0,1,2.73-2,13.49,13.49,0,0,1,4.41-.63,43.57,43.57,0,0,1,6.93.7l.31,5.47a12.19,12.19,0,0,0-4.55-.94,5.73,5.73,0,0,0-2.23.32,1,1,0,0,0-.7,1,1.15,1.15,0,0,0,.08.41,1.36,1.36,0,0,0,.28.41,4.77,4.77,0,0,0,.51.44c.2.15.69.5,1.48,1.05s1.39,1,1.83,1.34a10.41,10.41,0,0,1,1.67,1.59,5.44,5.44,0,0,1,.88,1.57,5.07,5.07,0,0,1,.3,1.75,5,5,0,0,1-.67,2.54,5.38,5.38,0,0,1-2,1.95,9.44,9.44,0,0,1-2.94,1.08,17.49,17.49,0,0,1-3.26.3,25.48,25.48,0,0,1-2.84-.19C474.88,172.45,473.55,172.22,472,171.9Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M491.4,172.27c.18-1.59.31-2.9.4-3.93s.18-2.34.27-3.94.16-3.38.22-5.32.12-4.19.16-6.76c1.69,0,3,0,4.06,0s2.26,0,4,0q-.35,3.7-.49,6.49c-.09,1.86-.16,3.83-.18,5.92-.06,3.7-.08,6.21-.08,7.54-1.55,0-2.88,0-4,0S493.19,172.24,491.4,172.27Zm1.23-22.45c.08-1.54.11-2.77.11-3.67,0-.26,0-.61,0-1.05,1.58,0,2.9,0,4,0,1.47,0,2.76,0,3.87,0,0,.4-.07.74-.08,1l-.12,2.43c0,.36,0,.79,0,1.28l-3.71,0Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M515.62,151.82a14.51,14.51,0,0,1,4.65.74,10.42,10.42,0,0,1,3.78,2.14,8.62,8.62,0,0,1,2.28,3.26,10.82,10.82,0,0,1,.73,4,12.21,12.21,0,0,1-.65,4,10,10,0,0,1-2.22,3.53,9.56,9.56,0,0,1-4,2.47,15.62,15.62,0,0,1-5.1.82,15.79,15.79,0,0,1-4.77-.72,10.09,10.09,0,0,1-3.82-2.11,8.75,8.75,0,0,1-2.3-3.32,10.74,10.74,0,0,1-.77-4.06,11.12,11.12,0,0,1,.75-4.06,9.46,9.46,0,0,1,2.38-3.48,10.92,10.92,0,0,1,4-2.37A15,15,0,0,1,515.62,151.82Zm-.14,5.74a3.8,3.8,0,0,0-3.7,2.53,7.24,7.24,0,0,0-.5,2.7,4.64,4.64,0,0,0,1.07,3.29,3.54,3.54,0,0,0,2.7,1.15,3.88,3.88,0,0,0,2.21-.66,3.94,3.94,0,0,0,1.47-1.93,7.53,7.53,0,0,0,.5-2.74,5.85,5.85,0,0,0-.39-2.19,3.31,3.31,0,0,0-1.28-1.56A3.65,3.65,0,0,0,515.48,157.56Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M529.76,172.27c.37-3,.66-6.22.86-9.8s.32-6.95.36-10.15c1.56,0,2.91,0,4,0,1.31,0,2.58,0,3.79,0l-.2,2.66a15.44,15.44,0,0,1,1.12-1.14,7.09,7.09,0,0,1,1.16-.82,7.5,7.5,0,0,1,1.47-.66,11.31,11.31,0,0,1,1.71-.4,13.56,13.56,0,0,1,1.95-.14,12.5,12.5,0,0,1,2.74.3A7.32,7.32,0,0,1,551,153a5.12,5.12,0,0,1,1.5,1.39,4.6,4.6,0,0,1,.8,1.78,9.92,9.92,0,0,1,.2,2c0,.49-.06,1.56-.16,3.2L553,168.4c-.06,1.39-.1,2.68-.1,3.87-1.31,0-2.54,0-3.69,0-1.54,0-3,0-4.23,0,.12-1.19.24-3,.38-5.29s.19-4,.19-4.93a4.32,4.32,0,0,0-.85-3.07,2.87,2.87,0,0,0-2.14-.87,3.5,3.5,0,0,0-2.14.74,4.62,4.62,0,0,0-1.55,2.16,17.17,17.17,0,0,0-.78,4.65q-.23,3.26-.3,6.61c-1.3,0-2.57,0-3.81,0S531.28,172.24,529.76,172.27Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M559,158c.25-1.29.41-2.18.47-2.67s.17-1.25.32-2.29a46.28,46.28,0,0,1,9.67-1.23,16.3,16.3,0,0,1,4.21.51,6.24,6.24,0,0,1,3,1.67,4.66,4.66,0,0,1,1.08,3.31c0,.72-.1,2.37-.29,4.92s-.35,5.9-.45,10c-1.28,0-2.55,0-3.81,0s-2.53,0-3.91,0c.18-1.44.37-4.39.59-8.87-.49.23-.88.4-1.16.51s-.77.26-1.47.47-1.25.38-1.65.52a4.19,4.19,0,0,0-1,.55,2.14,2.14,0,0,0-.62.68,1.61,1.61,0,0,0-.19.78,1.47,1.47,0,0,0,.53,1.14,2.5,2.5,0,0,0,1.68.47,7.23,7.23,0,0,0,.88-.07,9,9,0,0,0,1.29-.34c-.38,1-.83,2.46-1.35,4.39a20.1,20.1,0,0,1-3.19.27,10.51,10.51,0,0,1-4-.67,5,5,0,0,1-2.48-2.18,5.94,5.94,0,0,1-.87-3,5.24,5.24,0,0,1,.32-1.79,6,6,0,0,1,1-1.72,6.88,6.88,0,0,1,1.6-1.44,11.36,11.36,0,0,1,2.51-1.12,34.23,34.23,0,0,1,4-1c.68-.13,1.3-.27,1.87-.42a7.85,7.85,0,0,0,1.21-.39,2,2,0,0,0,.58-.39,1.27,1.27,0,0,0,.32-.47,1.25,1.25,0,0,0,.1-.51,1.3,1.3,0,0,0-.62-1.15,3.51,3.51,0,0,0-1.84-.4A24.23,24.23,0,0,0,559,158Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M581.32,172.27l.35-4.21.75-14.6.22-7.85,4.08,0c1.19,0,2.5,0,3.93,0l-.44,6-.72,14.77-.15,5.9c-1.4,0-2.66,0-3.78,0Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
            </g>
        </g>
    )
}

export default TopHex
