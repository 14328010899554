import React from 'react'
import { motion } from 'framer-motion'

const BottomRightHex = ({ ...rest }) => {
    return (
        <g id="Bottom_Right_Hex" data-name="Bottom Right Hex">
            <motion.path
                id="Dark_Blue_Hex"
                data-name="Dark Blue Hex"
                d="M753.52,806.88c-27,0-54.06.5-81-.42-5.47-.19-13-4.37-15.79-9Q615.31,727.25,575.73,656c-2.83-5.1-2.57-14.65.32-19.85q39.15-70.56,80.22-140c3-5,11.12-9.68,16.94-9.76q80.25-1.11,160.52,0c5.82.08,14,4.7,17,9.7q41.07,69.44,80.23,140c2.89,5.21,3.19,14.78.36,19.88q-39.49,71.28-81,141.42c-2.75,4.67-10.29,8.89-15.75,9.08C807.58,807.38,780.54,806.88,753.52,806.88Z"
                style={{ fill: '#002b70' }}
                {...rest}
            />
        </g>
    )
}

export default BottomRightHex
