import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import HexValues from '../SvgComps/HexValues'

const hero = () => {
    const isMobile = useMediaQuery('(max-width: 768px')
    return (
        <div className="hero">
            <div className="contentContainer hero__container">
                <section className="hero_left">
                    <div className="hero_left_container">
                        {isMobile && (
                            <div className="hero_left_container_bg">
                                <img
                                    src="/assets/BlueNE.png"
                                    alt="Blue New Evolution Initial Logo"
                                />
                            </div>
                        )}
                        <h2>New Evolution Digital</h2>
                        <p>
                            Turning ideas into reality by leveraging the webs
                            most powerful technologies for your next project.
                            Whether you want a portfolio Page, a revolutionary
                            e-commerce solution, or just want your message
                            viewed by the internet, our team will help deploy
                            your vision to a web space for everyone to use.
                        </p>
                    </div>
                </section>
                {!isMobile && (
                    <section className="hero_right">
                        <HexValues />
                    </section>
                )}
            </div>
        </div>
    )
}

export default hero
