import React from 'react'
import NextSeo from 'next-seo'
// Local Components
import Reviews from '../components/Reviews'

// Components From Components Library
import CallToAction from '../components/call'
import Services from '../components/services'
import { Hero } from '../components/home'

const Home = () => {
    return (
        <>
            <Hero />
            <section>
                <CallToAction />
                <div className="window">
                    <p>
                        Want To Network With Our Other Business?{' '}
                        <a href="/contact">Click Here To Find Out How</a>
                    </p>
                </div>
                <div className="section-2">
                    <div className="contentContainer review">
                        <Reviews />
                    </div>
                </div>
            </section>
            <Services />
        </>
    )
}

export default Home
