import { motion } from 'framer-motion'
import React from 'react'

const BottomLeftHex = ({ ...rest }) => {
    return (
        <g id="Bottom_Left_Hex" data-name="Bottom Left Hex">
            <motion.path
                id="Light_Blue_Hex"
                data-name="Light Blue Hex"
                d="M179.78,806.88c-27,0-54,.5-81-.42-5.48-.19-13-4.37-15.8-9Q41.57,727.25,2,656c-2.83-5.1-2.57-14.65.32-19.85q39.15-70.56,80.22-140c3-5,11.12-9.68,16.94-9.76q80.25-1.11,160.52,0c5.83.08,14,4.7,17,9.7q41.07,69.44,80.23,140c2.89,5.21,3.19,14.78.36,19.88q-39.49,71.28-81,141.42c-2.75,4.67-10.29,8.89-15.75,9.08C233.84,807.38,206.8,806.88,179.78,806.88Z"
                style={{ fill: '#1a4790', isolation: 'isolate' }}
                {...rest}
            />
            <g id="Sales_Based_Design" data-name="Sales Based Design">
                <motion.path
                    d="M64.64,633.68q-.26-4.15-.58-6.66a21.55,21.55,0,0,0,3.9,1.36,14.28,14.28,0,0,0,3,.38,6.18,6.18,0,0,0,2.71-.5,1.57,1.57,0,0,0,1-1.47,2,2,0,0,0-.1-.64,2.21,2.21,0,0,0-.37-.68,4.82,4.82,0,0,0-.78-.83l-3.68-3.2q-1.29-1.1-1.8-1.59A12.07,12.07,0,0,1,66.33,618a6.22,6.22,0,0,1-1.1-3.64,6.7,6.7,0,0,1,.42-2.33,7,7,0,0,1,3.49-3.86,9.73,9.73,0,0,1,3-1,19.5,19.5,0,0,1,3.27-.27,37.35,37.35,0,0,1,7.18.77c0,.89.09,1.72.14,2.47s.18,2.06.37,3.93a17.34,17.34,0,0,0-3.28-1,13.9,13.9,0,0,0-2.56-.29,5.09,5.09,0,0,0-2.53.57,1.64,1.64,0,0,0-1,1.41,2,2,0,0,0,.15.76,3.92,3.92,0,0,0,.59.87,10,10,0,0,0,1.13,1.09c.47.4,1.21,1,2.21,1.77l1.4,1.11a17.58,17.58,0,0,1,1.35,1.23A13.17,13.17,0,0,1,81.77,623a8.29,8.29,0,0,1,.81,1.29,5.38,5.38,0,0,1,.42,1.18,5.7,5.7,0,0,1,.14,1.28,7.11,7.11,0,0,1-.84,3.35,7,7,0,0,1-2.38,2.61,9.82,9.82,0,0,1-3.39,1.44,18.15,18.15,0,0,1-3.9.41A44.57,44.57,0,0,1,64.64,633.68Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M88.59,619.83c.25-1.29.4-2.17.47-2.66s.17-1.26.31-2.3A46.28,46.28,0,0,1,99,613.64a16.37,16.37,0,0,1,4.22.52,6.17,6.17,0,0,1,3,1.67,4.7,4.7,0,0,1,1.07,3.3c0,.73-.1,2.37-.29,4.92s-.34,5.9-.45,10c-1.28,0-2.55,0-3.81,0s-2.53,0-3.91,0q.27-2.15.59-8.87a12,12,0,0,1-1.16.51c-.28.11-.77.26-1.47.47s-1.25.38-1.64.53a3.67,3.67,0,0,0-1,.55,2,2,0,0,0-.62.68,1.67,1.67,0,0,0-.19.78,1.46,1.46,0,0,0,.53,1.13,2.44,2.44,0,0,0,1.68.47,5.48,5.48,0,0,0,.88-.07,7.83,7.83,0,0,0,1.29-.34c-.38,1-.83,2.47-1.35,4.4a20.08,20.08,0,0,1-3.18.27,10.63,10.63,0,0,1-4-.67,5,5,0,0,1-2.48-2.18,6,6,0,0,1-.87-3,5.1,5.1,0,0,1,.32-1.8,6.1,6.1,0,0,1,1-1.72,7,7,0,0,1,1.6-1.43,11.12,11.12,0,0,1,2.51-1.12,31.08,31.08,0,0,1,4-1c.68-.13,1.3-.27,1.87-.42a8.23,8.23,0,0,0,1.21-.39,2,2,0,0,0,.59-.39,1.36,1.36,0,0,0,.42-1,1.28,1.28,0,0,0-.63-1.14,3.4,3.4,0,0,0-1.83-.4A24.14,24.14,0,0,0,88.59,619.83Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M110.93,634.09l.35-4.2.76-14.6.21-7.86c1.81,0,3.18,0,4.09,0,1.18,0,2.49,0,3.93,0l-.45,6-.71,14.76-.16,5.9-3.77,0Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M142.69,628.19c-.17.78-.49,2.67-1,5.67a36.9,36.9,0,0,1-3.8.59c-1,.1-2.08.15-3.13.15a26.92,26.92,0,0,1-4.28-.32,10.21,10.21,0,0,1-3.37-1.14,8.37,8.37,0,0,1-2.37-2,8.59,8.59,0,0,1-1.45-2.9,11.82,11.82,0,0,1-.5-3.46,12.63,12.63,0,0,1,1-4.88,9.77,9.77,0,0,1,2.57-3.74,9.32,9.32,0,0,1,3.63-1.91,15.51,15.51,0,0,1,4.19-.57,17.3,17.3,0,0,1,4.25.52,7.82,7.82,0,0,1,3.43,1.81,7.4,7.4,0,0,1,2,2.91,10,10,0,0,1,.6,3.49,13.68,13.68,0,0,1-.26,2.48l-13.69,0c0,.21,0,.4,0,.57a3.42,3.42,0,0,0,1.51,3.09,6.51,6.51,0,0,0,3.67,1,16.63,16.63,0,0,0,2.94-.3A39.77,39.77,0,0,0,142.69,628.19Zm-12-6.31h6.44c0-.22,0-.41,0-.56a3.86,3.86,0,0,0-.36-1.74,2.23,2.23,0,0,0-1-1.05,3.52,3.52,0,0,0-2.65-.15,3,3,0,0,0-1,.55,3.4,3.4,0,0,0-.73.83,4.07,4.07,0,0,0-.43.9A10.77,10.77,0,0,0,130.68,621.88Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M146.87,633.72c-.05-.57-.1-1.17-.16-1.81s-.13-1.5-.25-2.6l-.16-1.41a29.13,29.13,0,0,0,3.26,1.1,9.86,9.86,0,0,0,2.51.36,5.53,5.53,0,0,0,1.55-.17,1.74,1.74,0,0,0,.87-.51,1.07,1.07,0,0,0,.27-.68,1.14,1.14,0,0,0-.2-.62,4.34,4.34,0,0,0-.93-.83c-1.06-.78-2-1.49-2.81-2.13a10.79,10.79,0,0,1-1.83-1.7,5.52,5.52,0,0,1-.94-1.6,5.08,5.08,0,0,1-.32-1.77,5.38,5.38,0,0,1,.93-3,5.21,5.21,0,0,1,2.73-2,13.21,13.21,0,0,1,4.41-.64,43.75,43.75,0,0,1,6.93.71l.31,5.46a12.37,12.37,0,0,0-4.55-.93,5.86,5.86,0,0,0-2.24.31,1,1,0,0,0-.69,1,1.15,1.15,0,0,0,.08.41,1.5,1.5,0,0,0,.28.41,4.77,4.77,0,0,0,.51.44l1.48,1q1.17.82,1.83,1.35a9.6,9.6,0,0,1,1.67,1.59,5.77,5.77,0,0,1,.88,1.56,5.15,5.15,0,0,1,.3,1.75,5,5,0,0,1-.67,2.55,5.43,5.43,0,0,1-2,1.94A9.72,9.72,0,0,1,157,634.3a17.49,17.49,0,0,1-3.26.3,26.14,26.14,0,0,1-2.85-.2C149.71,634.27,148.38,634.05,146.87,633.72Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M176.66,634.09c.27-2.86.45-5.12.54-6.76q.2-3.36.38-9.22c.13-3.9.19-6.66.19-8.26,0-.57,0-1.38,0-2.42,1.73,0,3.1.06,4.12.06,1.42,0,3.07,0,5-.09s3.51-.09,4.86-.09a30.4,30.4,0,0,1,3.48.14,8.77,8.77,0,0,1,2.39.63,5.62,5.62,0,0,1,1.71,1.11,5.39,5.39,0,0,1,1.19,1.87,7.25,7.25,0,0,1,.49,2.68,7.88,7.88,0,0,1-.28,2.13,6.63,6.63,0,0,1-.8,1.81,5.94,5.94,0,0,1-1.16,1.31,5.73,5.73,0,0,1-1.26.82,10.74,10.74,0,0,1-1.65.59,10.4,10.4,0,0,1,1.66.4,5.87,5.87,0,0,1,1.33.72,5.36,5.36,0,0,1,1.28,1.28,5.69,5.69,0,0,1,.85,1.75,7.18,7.18,0,0,1,.29,2,8,8,0,0,1-.49,2.79,6.54,6.54,0,0,1-1.37,2.25,6.35,6.35,0,0,1-1.93,1.37,9.24,9.24,0,0,1-2.44.78,36.93,36.93,0,0,1-3.71.23c-1.79.06-3.68.08-5.69.08l-5.14,0Zm8.25-5.35h3.75a9.66,9.66,0,0,0,2.19-.18,3.5,3.5,0,0,0,1.31-.56,2.53,2.53,0,0,0,.72-1,3.42,3.42,0,0,0,.26-1.35,2.68,2.68,0,0,0-.22-1.13,2,2,0,0,0-.64-.82,2.82,2.82,0,0,0-1.05-.48,9.23,9.23,0,0,0-2-.15h-4.12Zm.45-10.92h2.77a17.65,17.65,0,0,0,2-.07,5.32,5.32,0,0,0,1.12-.24,2.19,2.19,0,0,0,1-.59,2.33,2.33,0,0,0,.57-.91,3.3,3.3,0,0,0,.17-1,2.45,2.45,0,0,0-.35-1.29,2,2,0,0,0-1.1-.84,7.42,7.42,0,0,0-2.32-.27H185.6Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M206.56,619.83c.24-1.29.4-2.17.47-2.66s.17-1.26.31-2.3a46.28,46.28,0,0,1,9.67-1.23,16.42,16.42,0,0,1,4.22.52,6.17,6.17,0,0,1,3,1.67,4.7,4.7,0,0,1,1.07,3.3c0,.73-.1,2.37-.29,4.92s-.35,5.9-.45,10c-1.28,0-2.55,0-3.81,0s-2.53,0-3.91,0q.27-2.15.59-8.87a12,12,0,0,1-1.16.51c-.28.11-.77.26-1.47.47s-1.25.38-1.64.53a3.54,3.54,0,0,0-1,.55,2,2,0,0,0-.62.68,1.67,1.67,0,0,0-.19.78,1.46,1.46,0,0,0,.53,1.13,2.44,2.44,0,0,0,1.68.47,5.57,5.57,0,0,0,.88-.07,7.83,7.83,0,0,0,1.29-.34c-.38,1-.83,2.47-1.35,4.4a20.08,20.08,0,0,1-3.18.27,10.72,10.72,0,0,1-4-.67,5.07,5.07,0,0,1-2.48-2.18,6,6,0,0,1-.87-3,5.12,5.12,0,0,1,.33-1.8,6.1,6.1,0,0,1,1-1.72,7,7,0,0,1,1.6-1.43,10.91,10.91,0,0,1,2.51-1.12,31.08,31.08,0,0,1,4-1c.68-.13,1.3-.27,1.87-.42a8.66,8.66,0,0,0,1.21-.39,2,2,0,0,0,.58-.39,1.55,1.55,0,0,0,.33-.47,1.5,1.5,0,0,0,.09-.52,1.27,1.27,0,0,0-.62-1.14,3.42,3.42,0,0,0-1.84-.4A24.14,24.14,0,0,0,206.56,619.83Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M228.78,633.72c-.05-.57-.1-1.17-.15-1.81s-.14-1.5-.26-2.6l-.15-1.41a29.05,29.05,0,0,0,3.25,1.1,9.93,9.93,0,0,0,2.52.36,5.46,5.46,0,0,0,1.54-.17,1.74,1.74,0,0,0,.87-.51,1.09,1.09,0,0,0,.28-.68,1.08,1.08,0,0,0-.21-.62,4.22,4.22,0,0,0-.92-.83q-1.61-1.17-2.82-2.13a11.63,11.63,0,0,1-1.83-1.7,5.27,5.27,0,0,1-.93-1.6,4.87,4.87,0,0,1-.33-1.77,5.38,5.38,0,0,1,.93-3,5.19,5.19,0,0,1,2.74-2,13.15,13.15,0,0,1,4.4-.64,43.75,43.75,0,0,1,6.93.71l.32,5.46a12.45,12.45,0,0,0-4.55-.93,5.83,5.83,0,0,0-2.24.31,1,1,0,0,0-.69,1,1.14,1.14,0,0,0,.07.41,1.56,1.56,0,0,0,.29.41,3.37,3.37,0,0,0,.51.44l1.47,1c.78.55,1.39,1,1.84,1.35a10,10,0,0,1,1.67,1.59,5.74,5.74,0,0,1,.87,1.56,4.93,4.93,0,0,1,.31,1.75,5,5,0,0,1-.68,2.55,5.36,5.36,0,0,1-2,1.94,9.53,9.53,0,0,1-2.94,1.09,17.41,17.41,0,0,1-3.26.3,26.14,26.14,0,0,1-2.84-.2C231.62,634.27,230.3,634.05,228.78,633.72Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M267.59,628.19q-.25,1.17-1,5.67a36.62,36.62,0,0,1-3.81.59c-1,.1-2.07.15-3.13.15a26.79,26.79,0,0,1-4.27-.32,10.06,10.06,0,0,1-3.37-1.14,8.28,8.28,0,0,1-2.38-2,8.77,8.77,0,0,1-1.45-2.9,12.17,12.17,0,0,1-.5-3.46,12.46,12.46,0,0,1,1-4.88,9.76,9.76,0,0,1,2.56-3.74,9.32,9.32,0,0,1,3.63-1.91,15.59,15.59,0,0,1,4.2-.57,17.36,17.36,0,0,1,4.25.52,7.78,7.78,0,0,1,3.42,1.81,7.29,7.29,0,0,1,2,2.91,10,10,0,0,1,.6,3.49,13.6,13.6,0,0,1-.25,2.48l-13.69,0c0,.21,0,.4,0,.57a3.44,3.44,0,0,0,1.5,3.09,6.53,6.53,0,0,0,3.67,1,16.63,16.63,0,0,0,2.94-.3A39.77,39.77,0,0,0,267.59,628.19Zm-12-6.31H262c0-.22,0-.41,0-.56a3.86,3.86,0,0,0-.36-1.74,2.3,2.3,0,0,0-1-1.05,3.26,3.26,0,0,0-1.51-.34,3.41,3.41,0,0,0-1.14.19,2.88,2.88,0,0,0-1,.55,3.25,3.25,0,0,0-.74.83,4.57,4.57,0,0,0-.43.9A10.77,10.77,0,0,0,255.58,621.88Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M293.92,634.09c-1.39,0-2.55,0-3.47,0s-2.31,0-3.79,0l.11-2.48a17.27,17.27,0,0,1-1.33,1.2,7.39,7.39,0,0,1-1.46.89,8.28,8.28,0,0,1-1.91.66,9.25,9.25,0,0,1-2.12.24,9.06,9.06,0,0,1-3.5-.68,7.77,7.77,0,0,1-2.78-1.95,8.06,8.06,0,0,1-1.72-3,11.32,11.32,0,0,1-.57-3.58,13.47,13.47,0,0,1,1-5.16,10.19,10.19,0,0,1,2.71-4,9.83,9.83,0,0,1,3.77-2,16.45,16.45,0,0,1,4.54-.6,28.06,28.06,0,0,1,3.79.3q.16-2.33.22-6.51c1.6,0,2.9,0,3.92,0,1.31,0,2.64,0,4,0q-.57,5.8-1,14.16T293.92,634.09ZM287,619a9.38,9.38,0,0,0-2-.23,6.59,6.59,0,0,0-3.23.73,4.8,4.8,0,0,0-2,2.29,7.33,7.33,0,0,0-.71,3.05,4.92,4.92,0,0,0,.4,2,3.2,3.2,0,0,0,1.23,1.44,3.41,3.41,0,0,0,1.85.53,3.45,3.45,0,0,0,2-.61,4.28,4.28,0,0,0,1.45-1.78,8.94,8.94,0,0,0,.76-2.78C286.82,622.62,286.91,621.06,287,619Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M111.77,682.09c.29-3,.49-5.34.59-7.13q.24-4,.47-10.31.18-4.85.18-6.93v-2.29c1.52,0,2.84.06,3.94.06s2.47,0,4.2-.09,3.36-.09,4.88-.09a29.3,29.3,0,0,1,4.59.32,10.36,10.36,0,0,1,3.68,1.29,9,9,0,0,1,2.86,2.55,11.58,11.58,0,0,1,1.76,3.71,15.89,15.89,0,0,1,.61,4.36,19.09,19.09,0,0,1-.47,4.25,15.64,15.64,0,0,1-1.31,3.68,12.37,12.37,0,0,1-2,2.82,11.53,11.53,0,0,1-2.57,2,10.69,10.69,0,0,1-2.17,1,16.24,16.24,0,0,1-3,.57,45.29,45.29,0,0,1-4.78.23l-7.89-.06C114.64,682,113.44,682.05,111.77,682.09Zm8.45-5.88c.71.07,1.46.1,2.24.1A16.48,16.48,0,0,0,126,676a5.72,5.72,0,0,0,2.75-1.51,6.88,6.88,0,0,0,1.74-3,12.28,12.28,0,0,0,.57-3.71,8.28,8.28,0,0,0-.84-3.91,4.76,4.76,0,0,0-2.24-2.22,9.4,9.4,0,0,0-3.8-.62c-.93,0-2,0-3.32.11Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M161.77,676.19q-.26,1.17-1,5.67a36.62,36.62,0,0,1-3.81.59c-1,.1-2.07.15-3.13.15a27,27,0,0,1-4.28-.32,10.08,10.08,0,0,1-3.36-1.14,8.28,8.28,0,0,1-2.38-2,8.59,8.59,0,0,1-1.45-2.9,12.17,12.17,0,0,1-.5-3.46,12.46,12.46,0,0,1,1-4.88,9.76,9.76,0,0,1,2.56-3.74,9.32,9.32,0,0,1,3.63-1.91,15.53,15.53,0,0,1,4.2-.57,17.22,17.22,0,0,1,4.24.52A7.82,7.82,0,0,1,161,664a7.29,7.29,0,0,1,2,2.91,10,10,0,0,1,.6,3.49,14.55,14.55,0,0,1-.25,2.48l-13.7,0c0,.21,0,.4,0,.57a3.45,3.45,0,0,0,1.51,3.09,6.53,6.53,0,0,0,3.67,1,16.63,16.63,0,0,0,2.94-.3A39.77,39.77,0,0,0,161.77,676.19Zm-12-6.31h6.45c0-.22,0-.41,0-.56a3.85,3.85,0,0,0-.37-1.74,2.23,2.23,0,0,0-1-1.05,3.26,3.26,0,0,0-1.51-.34,3.37,3.37,0,0,0-1.14.19,2.88,2.88,0,0,0-1,.55,3.25,3.25,0,0,0-.74.83,4.07,4.07,0,0,0-.43.9A10.77,10.77,0,0,0,149.76,669.88Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M166,681.72c0-.57-.1-1.17-.15-1.81s-.14-1.5-.26-2.6l-.15-1.41a29.05,29.05,0,0,0,3.25,1.1,9.92,9.92,0,0,0,2.51.36,5.49,5.49,0,0,0,1.55-.17,1.74,1.74,0,0,0,.87-.51,1.07,1.07,0,0,0,.27-.68,1.14,1.14,0,0,0-.2-.62,4.34,4.34,0,0,0-.93-.83c-1.06-.78-2-1.49-2.81-2.13a11.63,11.63,0,0,1-1.83-1.7,5.52,5.52,0,0,1-.94-1.6,5.08,5.08,0,0,1-.32-1.77,5.38,5.38,0,0,1,.93-3,5.21,5.21,0,0,1,2.73-2,13.25,13.25,0,0,1,4.41-.64,43.75,43.75,0,0,1,6.93.71l.31,5.46a12.37,12.37,0,0,0-4.55-.93,5.77,5.77,0,0,0-2.23.31,1,1,0,0,0-.7,1,1.15,1.15,0,0,0,.08.41,1.5,1.5,0,0,0,.28.41,4.77,4.77,0,0,0,.51.44l1.48,1q1.17.82,1.83,1.35a9.6,9.6,0,0,1,1.67,1.59,5.52,5.52,0,0,1,.88,1.56,5.15,5.15,0,0,1,.3,1.75,5,5,0,0,1-.67,2.55,5.43,5.43,0,0,1-2,1.94A9.72,9.72,0,0,1,176,682.3a17.41,17.41,0,0,1-3.26.3,26,26,0,0,1-2.84-.2A40.11,40.11,0,0,1,166,681.72Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M185.31,682.09c.18-1.59.31-2.9.41-3.92s.17-2.35.26-3.95.16-3.37.22-5.31.12-4.19.16-6.76c1.69,0,3,0,4.06,0s2.26,0,4,0c-.22,2.46-.39,4.62-.48,6.48s-.17,3.84-.19,5.92q-.08,5.55-.08,7.54c-1.55,0-2.88,0-4,0S187.1,682.07,185.31,682.09Zm1.23-22.44c.08-1.55.12-2.77.12-3.67,0-.26,0-.61,0-1.06,1.57,0,2.89,0,4,0,1.47,0,2.76,0,3.87,0,0,.41-.07.75-.08,1l-.12,2.42c0,.37,0,.79,0,1.29-1.29,0-2.52,0-3.71,0S188,659.62,186.54,659.65Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M199.47,684.51a28.78,28.78,0,0,0,4.43,1.2,19.26,19.26,0,0,0,3.34.35,7.28,7.28,0,0,0,1.88-.23,4.7,4.7,0,0,0,1.34-.55,3.47,3.47,0,0,0,.87-.84,4,4,0,0,0,.56-1.14,7,7,0,0,0,.27-1.44c.05-.55.1-1.3.14-2.27-.51.46-1,.82-1.32,1.1a8.57,8.57,0,0,1-1.38.79,8.08,8.08,0,0,1-1.78.58,9.67,9.67,0,0,1-2,.21,8.77,8.77,0,0,1-4.48-1.16,7.23,7.23,0,0,1-3-3.22,10.35,10.35,0,0,1-1-4.47,12.14,12.14,0,0,1,1-5.12,10.54,10.54,0,0,1,3-3.86,11.12,11.12,0,0,1,4.3-2.19,21,21,0,0,1,5.31-.61c1.13,0,2.42.07,3.85.21s3.41.43,5.93.89l-.47,6.44-.4,7.07c0,.51,0,.93,0,1.25l-.15,6.35a12,12,0,0,1-.3,2.3,6.09,6.09,0,0,1-2,3.36,6.72,6.72,0,0,1-1.83,1.12,13.22,13.22,0,0,1-2.44.7,18.3,18.3,0,0,1-3.19.27c-.62,0-1.39,0-2.3-.08s-1.85-.15-2.8-.27-1.79-.24-2.48-.37-1.58-.32-2.65-.57l-.14-2.57C199.65,687.24,199.58,686.17,199.47,684.51Zm13.4-16.89a11.3,11.3,0,0,0-2.17-.24,6.39,6.39,0,0,0-3,.68,4.58,4.58,0,0,0-2,2,5.88,5.88,0,0,0-.7,2.78,5,5,0,0,0,.37,2,2.86,2.86,0,0,0,1.22,1.42,3.41,3.41,0,0,0,1.81.52,3.56,3.56,0,0,0,2.5-1,5.43,5.43,0,0,0,1.5-2.78A27.77,27.77,0,0,0,212.87,667.62Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
                <motion.path
                    d="M224.08,682.09c.37-3,.66-6.22.86-9.79s.32-7,.36-10.15c1.56,0,2.91,0,4,0,1.31,0,2.58,0,3.79,0l-.19,2.66a14.15,14.15,0,0,1,1.11-1.15,7.09,7.09,0,0,1,1.16-.82,8.1,8.1,0,0,1,1.47-.65,11.91,11.91,0,0,1,1.71-.4,12.24,12.24,0,0,1,1.95-.15,12.5,12.5,0,0,1,2.74.3,7.59,7.59,0,0,1,2.24.86,5.4,5.4,0,0,1,1.5,1.39,4.58,4.58,0,0,1,.8,1.79,9.82,9.82,0,0,1,.2,2c0,.5-.06,1.56-.16,3.2l-.34,7.07c-.06,1.4-.1,2.69-.1,3.87-1.31,0-2.54,0-3.68,0-1.55,0-3,0-4.24,0,.12-1.18.25-2.94.38-5.28s.19-4,.19-4.93a4.35,4.35,0,0,0-.85-3.08,2.86,2.86,0,0,0-2.14-.87,3.58,3.58,0,0,0-2.14.74,4.72,4.72,0,0,0-1.55,2.16,17.34,17.34,0,0,0-.78,4.66q-.22,3.24-.3,6.6c-1.3,0-2.57,0-3.81,0S225.6,682.07,224.08,682.09Z"
                    style={{ fill: '#fff' }}
                    {...rest}
                />
            </g>
        </g>
    )
}

export default BottomLeftHex
