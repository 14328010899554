import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Carousel } from 'antd'

const apiLink = process.env.NEXT_PUBLIC_SERVER_API
const Reviews = () => {
    const [reviews, setReviews] = useState([])

    useEffect(() => {
        const getReviews = async () => {
            axios.get(`${apiLink}/reviews`).then((res) => {
                setReviews(res.data)
            })
        }

        getReviews()
    }, [])

    return reviews.length > 0 ? (
        <>
            <h2>What Our Supports Say</h2>
            <Carousel autoplay autoplaySpeed="50" effect="fade">
                {reviews.map((review, i) => (
                    <ReviewItem review={review} key={`ReviewItem-${i + 1}`} />
                ))}
            </Carousel>
        </>
    ) : (
        <h2 className="review__loading">Loading Some Of Our Reviews</h2>
    )
}

const ReviewItem = ({ review }) => {
    return (
        <div className="review__item">
            <p className="review__statement">
                <em>"{review.statement}"</em>
            </p>
            <p className="review__author">
                <em>
                    - {review.contact.First_Name} {review.contact.last_name}
                    {review.contact.company_name &&
                        `, ${review.contact.company_name}`}
                </em>
            </p>
            <div
                className="review__stars"
                style={{ '--rating': review.rating }}
            />
        </div>
    )
}
export default Reviews
